<template>
    <AuthorizationCard :intention="intention" depressed class="mx-auto" max-width="600" @close="close"/>
</template>

<script>
import AuthorizationCard from '@/components/cards/AuthorizationCard'

export default {
    name: 'AuthorizationView',
    components: { AuthorizationCard },
    props: {
        intention: Object
    },
    methods: {
        close() {
            this.$router.push({ name: 'home' })
        },
    },
}
</script>
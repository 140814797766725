var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "align-content": "center", justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "6" } },
        [
          _c("v-progress-linear", {
            staticClass: "mt-4",
            attrs: { color: "primary", rounded: "", height: "5px" },
            model: {
              value: _vm.progress,
              callback: function($$v) {
                _vm.progress = $$v
              },
              expression: "progress"
            }
          })
        ],
        1
      ),
      _c("v-col", { staticClass: "text-center pt-0", attrs: { cols: "12" } }, [
        _vm._v(" O código expira em " + _vm._s(_vm.expires_in) + " segundos ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AuthorizationCard", {
    staticClass: "mx-auto",
    attrs: { intention: _vm.intention, depressed: "", "max-width": "600" },
    on: { close: _vm.close }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-card :max-width="maxWidth" class="elevation-0">
        <v-card-title>
            <span>{{ $t('cards.AuthorizationCard.title') }}</span>
        </v-card-title>

        <v-card-text v-html="computedDescription">
        </v-card-text>

        <v-list dense>
            <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-content>
                    {{ item.label }}
                </v-list-item-content>
                <v-list-item-action>
                    <v-skeleton-loader type="chip" v-if="item.loading"/>
                    <span v-else>{{ item.value }}</span>
                </v-list-item-action> 
            </v-list-item>
        </v-list>

        <v-card-text>
            <div class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary"/>
            </div>
            <template v-else>
                <div class="text-center">
                    <vueQrcode v-if="intention && intention.code" size="280" :width="280" :value="intention.code"/>
                </div>
                <div class="text-center">
                    {{ $t('cards.AuthorizationCard.authorization_code') }}
                </div>

                <div class="display-1 text-center">
                    <span>{{ intention ? intention.code : '' }}</span>
                </div>

                <ExpireProgress v-if="intention && intention.expires_at" @expired="onExpired" :expires_at="intention.expires_at" :created_at="intention.created_at"/>

                <template v-if="isConfirmed">
                    <v-alert type="success" class="mt-3">
                        {{ $t('cards.AuthorizationCard.success_message') }}
                    </v-alert>

                    <v-btn color="secondary" block @click="back">{{ $t('actions.back') }}</v-btn>
                </template>
            </template>
        </v-card-text>

        <v-card-text>
            <v-btn v-if="!isConfirmed" color="primary" elevation="0" height="42px" class="mb-2" block @click="confirm" large>{{ $t('actions.accept') }}</v-btn>
            <v-btn v-if="!isConfirmed" outlined color="error" block height="42px" @click="cancel" large>{{ $t('actions.not_accept') }}</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapFilters } from '@/utils/mapFilters'
import ExpireProgress from '@/components/progressBars/ExpireProgress'
import VueQrcode from 'vue-qrcode'
import Intention from '@/models/Intention'

export default {
    name: 'AuthorizationCard',
    props: {
        maxWidth: [Number, String],
        intention: Intention,
    },
    components: { VueQrcode, ExpireProgress },
    data: vm => ({
        subsidiary     : null,
        loading        : false,
        loadingCancel  : false,
        code           : null,
        total          : null,
        balanceResponse: null,
        expires_at     : null,
        error          : null,
        isConfirmed    : false,
        hasError       : false,
    }),
    async created() {
        if (!this.intention) {
            this.$router.push({ name: 'home' })
            return
        }

        await this.fetchBalance()
    },
    methods: {
        async fetchBalance() {
            this.loading = true

            let response = await this.$axios({
                url: 'customer/balance',
                method: 'get',
            }).catch(err => {
                this.error = true
                this.loading = false
            })


            if (!response)
                return

            this.balanceResponse = response.data

            this.loading = false
        },
        async cancel() {
            this.loadingCancel = true
            this.hasError = false

            let response = await this.$axios({
                url: 'points/intention',
                method: 'delete',
            })

            this.loadingCancel = false

            if (!response) {
                this.hasError = true
                return
            }

            this.code = null
            this.subsidiary = null
            await this.$store.dispatch('authorization/deleteAuthorization')

            this.$emit('close')
        },

        confirm() {
            this.isConfirmed = true
        },

        back() {
            this.$router.push({ name: 'home' })
        },

        onExpired() {
            this.$router.push({ name: 'home' })
        },

        ...mapFilters(['currency', 'dynamicPoint']),
    },
    computed: {
        items() {
            if (!this.intention || !this.balanceResponse)
                return []

            let balanceAmount = this.balanceResponse.amount_balance
            let balanceValue = this.balanceResponse.value_balance

            return [
                { label: this.$t('cards.AuthorizationCard.list.balance'),                   loading: !this.balanceResponse, value: this.dynamicPoint(balanceAmount) },
                { label: this.$t('cards.AuthorizationCard.list.total'),                     loading: !this.balanceResponse, value: this.currency(this.intention.value) },
                { label: this.$t('cards.AuthorizationCard.list.consumption_points'),        loading: !this.balanceResponse, value: this.dynamicPoint(this.intention.amount) },
                { label: this.$t('cards.AuthorizationCard.list.balance_after_consumption'), loading: !this.balanceResponse, value: `${this.dynamicPoint(balanceAmount - this.intention.amount)} | ${this.currency(balanceValue - this.intention.value)}` },
            ]
        },
        computedDescription() {
            return this.$t('cards.AuthorizationCard.description', { subsidiary: this.intention && this.intention.company ? this.intention.company.name : '' })
        },
    },
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0", attrs: { "max-width": _vm.maxWidth } },
    [
      _c("v-card-title", [
        _c("span", [_vm._v(_vm._s(_vm.$t("cards.AuthorizationCard.title")))])
      ]),
      _c("v-card-text", {
        domProps: { innerHTML: _vm._s(_vm.computedDescription) }
      }),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "v-list-item",
            { key: index },
            [
              _c("v-list-item-content", [
                _vm._v(" " + _vm._s(item.label) + " ")
              ]),
              _c(
                "v-list-item-action",
                [
                  item.loading
                    ? _c("v-skeleton-loader", { attrs: { type: "chip" } })
                    : _c("span", [_vm._v(_vm._s(item.value))])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            : [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm.intention && _vm.intention.code
                      ? _c("vueQrcode", {
                          attrs: {
                            size: "280",
                            width: 280,
                            value: _vm.intention.code
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("cards.AuthorizationCard.authorization_code")
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "display-1 text-center" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.intention ? _vm.intention.code : ""))
                  ])
                ]),
                _vm.intention && _vm.intention.expires_at
                  ? _c("ExpireProgress", {
                      attrs: {
                        expires_at: _vm.intention.expires_at,
                        created_at: _vm.intention.created_at
                      },
                      on: { expired: _vm.onExpired }
                    })
                  : _vm._e(),
                _vm.isConfirmed
                  ? [
                      _c(
                        "v-alert",
                        { staticClass: "mt-3", attrs: { type: "success" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "cards.AuthorizationCard.success_message"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", block: "" },
                          on: { click: _vm.back }
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.back")))]
                      )
                    ]
                  : _vm._e()
              ]
        ],
        2
      ),
      _c(
        "v-card-text",
        [
          !_vm.isConfirmed
            ? _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    color: "primary",
                    elevation: "0",
                    height: "42px",
                    block: "",
                    large: ""
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v(_vm._s(_vm.$t("actions.accept")))]
              )
            : _vm._e(),
          !_vm.isConfirmed
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    color: "error",
                    block: "",
                    height: "42px",
                    large: ""
                  },
                  on: { click: _vm.cancel }
                },
                [_vm._v(_vm._s(_vm.$t("actions.not_accept")))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
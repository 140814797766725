<template>
    <v-row align-content="center" justify="center">
        <v-col cols="6">
            <v-progress-linear
                class="mt-4"
                v-model="progress"
                color="primary"
                rounded
                height="5px"
            ></v-progress-linear>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
            O código expira em {{ expires_in }} segundos
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        expires_at: {
            required: true,
        },
        created_at: {
            required: true
        }
    },
    name: 'ExpireProgress',
    data: vm => {
        return {
            expires_in: vm.getExpiresIn(),
            progress: vm.getProgress()
        }
    },
    created() {
        setInterval(() => {
            this.expires_in = this.getExpiresIn()

            if (this.expires_in <= 0)
                this.$emit('expired')

            this.progress = this.getProgress()
        }, 1000);
    },
    methods: {
        getExpiresIn() {
            return Math.floor((this.expires_at - Date.now()) / 1000)
        }, 
        getProgress() {
            return Math.floor((((this.expires_at - Date.now()) / (this.expires_at - this.created_at))) * 100)
        }
    }
}
</script>
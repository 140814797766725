import { render, staticRenderFns } from "./AuthorizationCard.vue?vue&type=template&id=0a2db206&"
import script from "./AuthorizationCard.vue?vue&type=script&lang=js&"
export * from "./AuthorizationCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VCardTitle,VList,VListItem,VListItemAction,VListItemContent,VProgressCircular,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-customer/cplug-fid-front-customer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a2db206')) {
      api.createRecord('0a2db206', component.options)
    } else {
      api.reload('0a2db206', component.options)
    }
    module.hot.accept("./AuthorizationCard.vue?vue&type=template&id=0a2db206&", function () {
      api.rerender('0a2db206', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cards/AuthorizationCard.vue"
export default component.exports